import React from 'react';
import Content from '../views/ROI/Fleet';

export default ({location}) => <Content location={location} seo={{
  title: ' Beregn din besparelse på din flåde med ROI beregner',
  lang: 'da-DK',
  description: 'Reducer omkostninger med en elektronisk kørebog. Brug vores ROI beregner til at estimere, hvor meget du kan spare ved at benytte OBI+ flådestyring.',
  meta: [{
    name: 'keywords',
    content: 'ROI beregner for flåder'
  }]
}}/>